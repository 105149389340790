var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"EZcmQzsbYCBgbcIhm5dzE"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { initSentry } from '@libs/initSentry';

if (process.env.ENABLE_SENTRY === 'true') {
  initSentry(process.env.SENTRY_DSN || '', process.env.LMC_ENVIRONMENT);
}
